
.ls{-webkit-overflow-scrolling:touch;overflow-y:scroll}

.ls > * {
	transform:translateZ(0);
}
/* Tamaño del scroll */
.ls::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

 /* Estilos barra (thumb) de scroll */
.ls::-webkit-scrollbar-thumb {
  background: #5ab;
  border-radius: 0;
}

.ls::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.ls::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

 /* Estilos track de scroll */
.ls::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 0;
}

.ls::-webkit-scrollbar-track:hover, 
.ls::-webkit-scrollbar-track:active {
  background: #fff;
}