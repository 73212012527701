.d{
	text-align: center;
	margin:30px;
}
.i{
	width: 280px;
}
.p{
	text-align: justify;
	margin: 0 auto;
}
section{
	width: 500px;
	margin: 0 auto;
}