.ls{
	padding: 0;
}
.ht{
	border: 1px solid #285;
	border-radius:8px;
	padding:3px 1px;
	font-size:0.8em;
	font-weight:bolder;
	cursor: pointer;
	display: block;
	text-align:center;
	color:#285;
	margin:3px;
}
.ht:hover{
	background-color:#285;
	color:white;
}