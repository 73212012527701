.s,.e{
	background-color: white;
	text-align: left;
	/*-webkit-transition: background-color 1s,opacity 3s ease;
	-moz-transition: background-color 1s,opacity 3s ease;
	-o-transition: background-color 1s,opacity 3s ease;
	-ms-transition: background-color 1s,opacity 3s ease;
	transition: background-color 1s,opacity 3s ease;*/

    -webkit-transition: all 2s,opacity 3s ease;
    -moz-transition: all 2s,opacity 3s ease;
    -o-transition: all 2s,opacity 3s ease;
    -ms-transition: all 2s,opacity 3s ease;
    transition: all 2s,opacity 3s ease;

	min-height:400px;
	text-align:center;
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
    opacity: 1;
}

.e:hover,.s:hover{
	box-shadow:0 22px 33px rgba(0,0,0,.2);-webkit-transform:translateY(-2px);transform:translateY(-2px);
}

.hee{
    opacity: 0;
}
.di {
    vertical-align: top;
    display: inline-block;
    word-wrap: break-word;
    /*padding-bottom: 10px;*/
    /*width:350px;*/
    width: 100%;
}
.l{
	display:block;
    text-decoration:none;
}
.l:visited{
    color: #555;
}
.sp, .llsp {
    font-size:26px;
    color: black;
    font-family: 'Titillium Web',sans-serif;
    padding: 10px;
    font-weight: bold;
}
.hp {
    font-size:26px;
    color: black;
    font-family: 'Titillium Web',sans-serif;
    padding: 10px;
    font-weight: bold;
}
.i{
    opacity:1;
    width:100%;
    height:200px;
    object-fit:contain;
    /*-webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    -ms-transition: all 2s ease;
    transition: all 2s ease;*/
    overflow:hidden;
    display:block;
    object-position:top;

    -webkit-transition: all 2s,opacity 3s ease;
    -moz-transition: all 2s,opacity 3s ease;
    -o-transition: all 2s,opacity 3s ease;
    -ms-transition: all 2s,opacity 3s ease;
    transition: all 2s,opacity 3s ease;
}

.pt{
	color:black;
    font-size: 1.275rem;
    /*font-family: "Roboto", "Helvetica", "Arial", sans-serif;*/
    word-break: break-word;
}
.sty,.sc,.sti{
	font-size:0.875rem;
    /*font-family:"Roboto", "Helvetica", "Arial", sans-serif;*/
    display:block;
    margin-top:5px;
}
.h{
	text-align:left;
	margin:20px 10px 10px 10px;
    /*width:330px;*/
    min-height:100px;
}
.sh{display:block;}
.hi{opacity:0;}
.tt{border-left:2px solid #3ee443;padding:3px;cursor:pointer;}
.tt:hover{background-color:#b4f9b6}
.tp{margin:10px;}
.cc{border-bottom:2px solid #3ee443;padding:3px;cursor:pointer;}
.cc:hover{background-color:#b4f9b6}