.ih{width:100%;height:200px;object-fit:cover;}
.nd {
    /*font-family:'Roboto',sans-serif;*/
    font-size:1em;
    white-space:pre-wrap;
    word-break:break-word;
    text-align:justify;margin: 0;
}
.t{/*font-family:'Roboto',sans-serif;*/}
.np{color:red;margin:0;font-weight:bold;display:inline-block;margin:5px;}
.npc{display:block;}
.t{font-weight:bold;}
.rr{word-break:break-all;}
.lk{
    margin-right:5px;
    border-radius:8px;
    padding:3px;
    font-size:2.8em;
    color:#CF5;
    background-color:white;
    font-family: 'Titillium Web','Roboto',-apple-system, BlinkMacSystemFont, "Segoe UI","Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.lk:hover{
	font-weight:bolder;
}
.ni{width:30px;height:30px;margin-right:3px;}
.nio{width:50px;height:50px;margin-top:5px;margin-right:3px;cursor:zoom-in;border-bottom-right-radius:.25em;}
.nio:hover{}
.do{float:right;}
.dc{float:left;width:182px;font-size:0.9em;text-decoration:none;}
.dv{margin-top:10px;margin-bottom:10px;text-decoration:none;}
.dn{float:left;margin-top:10px;margin-bottom:10px;text-decoration:none;}
.box h3{
  text-align:center;
    position:relative;
    top:80px;
}
.gni{
  box-shadow: 11px -5px 4px -6px #aaa;
  border-top: 10px;
  margin-bottom:20px;
  border-radius:8px;
}
.spp{
	background-color:#4aF;
	color:white;
	padding:2px;
	border-radius:5px;
	font-weight:bolder;

}
.spn{
  border:1px solid #4aF;
  background-color:white;
  color:#4aF;
  padding:2px;
  border-radius:5px;
  font-weight:bolder;

}
.timeIcon {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
.vertical-timeline-element {
  margin: 1em 0;
}
.dtl {
  font-size: 0.5em;
}
@media (min-width:320px) and (max-width:600px){
  .ni{display:none;}
  .dc{width:auto;}
  .dv{float:none;}
}
@media (max-width:320px){
  .ni{display:none;}
  .dc{width:170px;}
  .dv{float:none;}
}
@media (min-width:960px) and (max-width:1430px){
  .ni{display:none;}
  .dc{width:auto;}
  .dv{float:none;}
}
